import { EditorReadyFn } from '@wix/platform-editor-sdk';

import { interactionEnded, interactionStarted, toMonitored } from '../../utils/monitoring';
import * as pagesGroup from '../wrappers/pagesGroup';
import { MEMBERS_PAGES_GROUP_NAME as MA_PAGES_GROUP, SANTA_MEMBERS_APP_ID } from '../constants';
import { addPageToGroup } from '../wrappers/pagesGroup';
import * as pagesWrapper from '../wrappers/pages';
import * as componentsWrapper from '../wrappers/components';
import { startSequentialPromises } from '../enforceSequentiality';

export const editorReadyMembersAreaOnBlocks: EditorReadyFn = async (editorSDK, _appToken, options) => {
  try {
    interactionStarted('editorReady');

    if (options.firstInstall) {
      const editorReadyTransaction = async () => {
        await toMonitored('install.createPagesGroup', async () => pagesGroup.create(editorSDK, MA_PAGES_GROUP));
        const pageTitle = 'Profile';
        const pageRef = await pagesWrapper.addPage({ editorSDK, pageTitle, pageUriSEO: '' });
        await editorSDK.pages.navigateTo('', { pageRef });

        await editorSDK.document.application.add('', {
          appDefinitionId: 'b976560c-3122-4351-878f-453f337b7245',
          managingAppDefId: SANTA_MEMBERS_APP_ID,
          isSilent: true,
        });

        await addPageToGroup(editorSDK, MA_PAGES_GROUP, pageRef.id);
      };

      await componentsWrapper.registerToComponentAddedToStageEvent(editorSDK);
      startSequentialPromises();

      await editorReadyTransaction();
    }
    interactionEnded('editorReady');
  } catch (e) {
    console.log(e);
  }
};
