import Experiments from '@wix/wix-experiments';

let conductedExperiments: Experiments;

async function maybeConductExperiments() {
  if (!conductedExperiments) {
    conductedExperiments = new Experiments({ scope: 'members-area' });
  }
  await conductedExperiments.ready();
}

async function isADIHorizontalLayoutEnabled() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.ADIHorizontalLayout') === 'true';
}

async function isAddMinHeightEnabled() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.AddMinHeight') === 'true';
}

async function isAddUninstallMAActionEnabled() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.AddUninstallMAAction') === 'true';
}

async function shouldSetResponsiveLayoutForApps() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.setResponsiveLayoutForApps') === 'true';
}

async function shouldRevertOnInstallationErrors() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.RevertOnInstallationErrors') === 'true';
}

async function shouldFixCorruptedRouterPatterns() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.FixCorruptedRouterPatterns') === 'true';
}

async function shouldDeleteMobileComponents() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.handleMobileComponentsDeletion') === 'true';
}

async function shouldFixLoginBarResponsiveLayout() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.fixLoginBarResponsiveLayout') === 'true';
}

async function shouldAddOpenMembersAccountBmActionToMyBusinessPanel() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.myBusinessOpenMembersAccountBmDashboard') === 'true';
}

async function shouldEnableGeneralSettings() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.generalSettings') === 'true';
}

async function shouldDisableEditorReadyTransaction() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.disableEditorReadyTransaction') === 'true';
}

async function shouldDisableParallelAppInstall() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.disableParallelAppInstall') === 'true';
}

async function shouldEnableSignUpPrivacyNoteType() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.ident.EnableSignUpPrivacyNoteType') === 'true';
}

async function shouldUseMembersAreaOnBlocksInstallFlow() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.profilePageBoBInstallation') === 'true';
}

export {
  maybeConductExperiments,
  isADIHorizontalLayoutEnabled,
  isAddMinHeightEnabled,
  isAddUninstallMAActionEnabled,
  shouldSetResponsiveLayoutForApps,
  shouldRevertOnInstallationErrors,
  shouldFixCorruptedRouterPatterns,
  shouldDeleteMobileComponents,
  shouldFixLoginBarResponsiveLayout,
  shouldAddOpenMembersAccountBmActionToMyBusinessPanel,
  shouldEnableGeneralSettings,
  shouldDisableEditorReadyTransaction,
  shouldDisableParallelAppInstall,
  shouldEnableSignUpPrivacyNoteType,
  shouldUseMembersAreaOnBlocksInstallFlow,
};
